<!-- eslint-disable vue/no-v-html -->
<template>
  <CommonsModalsModalBody :svg="svg" height="auto">
    <template #help>
      <div v-html="help" />
    </template>
    <template #title> Nouveau {{ textTier }} </template>

    <template #content>
      <div>
        <div v-if="!isEditMode" class="ntp-switch-box">
          <uds-switch
            label="Importer un fichier CSV"
            :value="isImportCsv"
            @change="isImportCsv = !isImportCsv"
          />
          <uds-tooltip
            :tooltip-text="`Importez vos ${textTier}s au format CSV.`"
            style="z-index: 9999999"
          >
            <uds-icon class="icon" icon-name="info" :color="udsColors.udsNeutral700" size="small" />
          </uds-tooltip>
        </div>
        <div v-if="isImportCsv" class="ntp-file-container">
          <CommonsDropzoneContainer
            :extensions="['text/csv']"
            :max-files="1"
            :max-size="2"
            style="width: 100%"
            :show-drop-box-button="true"
            @updated="files = $event"
          />
          <div class="text-xs-center">
            Importez vos {{ textTier }}s au format CSV
            <div>
              (<a :href="isAccruals ? csvSampleAccrual : csvSampleCashBased"
                >Télécharger le modèle</a
              >).
            </div>
          </div>
          <div class="text-xs-center">
            Il est impératif de ne pas supprimer ou modifier l'ordre des colonnes (1 500 lignes
            maximum).
          </div>
          <div class="text-xs-center">
            Pour ajouter un numéro de téléphone, la cellule doit être au format texte.<br >
            Comment faire ? Placer une apostrophe devant le numéro de téléphone:<br >
            '0601020304
          </div>
        </div>
        <template v-else>
          <div class="pf-form">
            <div class="pf-row">
              <CommonsInputsLegalName
                :selected-item="tier.company"
                :error="err.company"
                @update="tier.company = $event"
                @select="overrideTierForm($event)"
                @clear="clearValues"
              />
              <uds-input-select-auto-popper
                label="Catégorie"
                :value="tier.idAccount"
                :items="filteredCategories"
                item-value="id"
                item-text="name"
                :error="err.idAccount"
                is-required
                :tooltip-text="`Définir pour votre comptabilité à quel type de vente correspond ce ${textTier}.`"
                @select="tier.idAccount = $event || null"
              />
            </div>
            <div class="pf-row">
              <uds-input-string
                label="Interlocuteur"
                :value="tier.fullname"
                @change="tier.fullname = $event || null"
              />
              <uds-input-select-popper
                label="Forme Juridique"
                :value="tier.legalForm"
                :items="legalFormList"
                item-value="id"
                item-text="name"
                @select="tier.legalForm = $event || null"
              />
            </div>
            <div v-if="isCustomer" class="pf-row">
              <uds-input-select-auto-popper
                label="Conditions de règlement"
                :value="tier.termOfPayment"
                :items="TermOfPaymentList"
                item-value="id"
                item-text="label"
                :clearable="true"
                @select="tier.termOfPayment = $event || null"
              />
            </div>
            <div class="pf-row">
              <uds-input-string
                label="Adresse mail"
                :value="tier.email"
                :error="err.email"
                tooltip-text="En remplissant ce champs, vous pourrez envoyer par mail les documents de vente."
                @change="tier.email = $event || null"
              />
              <uds-input-string
                label="Téléphone"
                :value="tier.phone"
                :error="err.phone"
                @change="tier.phone = $event || null"
              />
            </div>
            <div class="pf-row">
              <uds-input-string
                label="Siret"
                :value="tier.nSiret"
                :error="err.nSiret"
                @change="tier.nSiret = $event || null"
              />
              <uds-input-string
                label="N° TVA intracom"
                :value="tier.nTva"
                :error="err.nTva"
                @change="tier.nTva = $event || null"
              />
              <template v-if="isAccruals">
                <uds-input-amount
                  label="Solde initial"
                  :value="tier.soldeInit"
                  @change="tier.soldeInit = $event"
                />
              </template>
            </div>
            <div class="pf-row">
              <uds-input-string
                label="Adresse de facturation"
                :value="tier.address"
                :error="err.address"
                is-required
                @change="tier.address = $event || null"
              />
            </div>
            <div class="pf-row">
              <uds-input-string
                label="Code postal"
                :value="tier.zipCode"
                :error="err.zipCode"
                is-required
                style="max-width: 115px"
                @change="tier.zipCode = $event || null"
              />
              <uds-input-string
                label="Ville"
                :value="tier.city"
                :error="err.city"
                is-required
                @change="tier.city = $event || null"
              />
              <uds-input-select-auto-popper
                label="Pays"
                :value="tier.idCountry"
                :items="countriesList"
                item-value="id"
                item-text="name"
                :clearable="true"
                @select="tier.idCountry = $event || null"
              />
            </div>
            <div class="pf-row">
              <div class="row-checkbox">
                <uds-input-checkbox
                  label="Adresse de livraison différente de l'adresse de facturation"
                  :is-checked="shouldUseTwoAddress"
                  @input="shouldUseTwoAddress = !shouldUseTwoAddress"
                />
              </div>
            </div>
            <div v-if="shouldUseTwoAddress" class="pf-row">
              <uds-input-string
                label="Adresse de livraison"
                :value="tier.deliveryAddress"
                :error="err.deliveryAddress"
                is-required
                @change="tier.deliveryAddress = $event || null"
              />
            </div>
            <div v-if="shouldUseTwoAddress" class="pf-row">
              <uds-input-string
                label="Code postal"
                :value="tier.deliveryZipCode"
                :error="err.deliveryZipCode"
                is-required
                style="max-width: 115px"
                @change="tier.deliveryZipCode = $event || null"
              />
              <uds-input-string
                label="Ville"
                :value="tier.deliveryCity"
                is-required
                :error="err.deliveryCity"
                @change="tier.deliveryCity = $event || null"
              />
              <uds-input-select-auto-popper
                label="Pays"
                :value="tier.deliveryIdCountry"
                :items="countriesList"
                item-value="id"
                item-text="name"
                :clearable="true"
                @select="tier.deliveryIdCountry = $event || null"
              />
            </div>
          </div>
        </template>
      </div>
    </template>

    <template #footer>
      <uds-main-button type="tertiary" size="small" @click="$emit('cancel')">
        Annuler
      </uds-main-button>
      <uds-main-button
        v-if="isImportCsv"
        size="small"
        :disabled="!isImportCsvFormValid"
        :loading="isLoading"
        @click="addNewTiersCsv"
        >Enregistrer</uds-main-button
      >
      <uds-main-button
        v-else
        size="small"
        :disabled="!isValid"
        :loading="isLoading"
        @click="isValid && saveThirdParty()"
        >Enregistrer</uds-main-button
      >
    </template>
  </CommonsModalsModalBody>
</template>

<script setup lang="ts">
import type {
  CreateThirdPartyForm,
  UpdateThirdPartyForm,
  IThirdParty,
  IAccount,
  LegalFormSiren,
  TermOfPaymentList,
} from "@silexpert/core";
import { ThirdPartyType, CountryList, CountryId, LegalForm } from "@silexpert/core";

type TierForm = {
  [key in keyof CreateThirdPartyForm]: CreateThirdPartyForm[key] | null | undefined;
};

const societyStore = useSocietyStore();
const userStore = useUsersStore();
const thirdPartyStore = useThirdPartiesStore();
const { getCategoriesForAnnotating } = useAnnotationUtilsComposable();
const udsColors = getUdsColors();

const emit = defineEmits(["cancel", "select", "close"]);

const props = withDefaults(
  defineProps<{
    forceImportCsv?: boolean;
    // droppedFiles?: File[];
    isCustomer: boolean;
    editTier?: IThirdParty | null;
    help?: string;
  }>(),
  {
    forceImportCsv: false,
    editTier: null,
    help: `Ajouter un tier vous permettra d'y accéder plus facilement lors de l'annotation de vos opérations bancaires, factures d'achats et factures de ventes.`,
  },
);

const { isComptalib } = useBrandsComposable();
const svg = isComptalib() ? "ComptalibThirdPartySvg" : "ThirdPartySvg";

function defaultValues(): TierForm {
  if (props.editTier) {
    return props.editTier;
  }
  return {
    idThirdPartyType: props.isCustomer ? ThirdPartyType.CUSTOMER : ThirdPartyType.PROVIDER,
    company: null,
    fullname: null,
    idAccount: null,
    address: null,
    zipCode: null,
    city: null,
    idCountry: null,
    phone: null,
    email: null,
    nSiret: null,
    nTva: null,
    soldeInit: 0,
    isGeneric: true,
    deliveryAddress: null,
    deliveryCity: null,
    deliveryZipCode: null,
    deliveryIdCountry: null,
    ...(props.isCustomer ? { termOfPayment: null } : {}),
  };
}

onMounted(() => {
  isImportCsv.value = props.forceImportCsv;
});

const tier = ref<TierForm>(defaultValues());
const isImportCsv = ref<boolean>(false);
const files = ref<any[]>([]);
// const externalConnectorFiles = ref<File[]>([]);

const isLoading = ref<boolean>(false);
const rejected = ref<boolean>(false);
const shouldUseTwoAddress = ref<boolean>(false);

const csvSampleCashBased: string =
  "https://s3.eu-west-3.amazonaws.com/resources-clementine/sampleCSV/importTiers.csv";

const csvSampleAccrual: string =
  "https://s3.eu-west-3.amazonaws.com/resources-clementine/sampleCSV/importTiersEnga.csv";

const countriesList = CountryList;
const legalFormList = Object.values(LegalForm);

const textTier = computed<string>(() => (props.isCustomer ? "client" : "fournisseur"));
const isEditMode = computed<boolean>(() => isDefined(props.editTier));
const isAccruals = computed<boolean>(() => societyStore.isAccruals);
const hasAccountNumberDisplay = computed<boolean>(
  () => userStore.user?.hasAccountNumberDisplay ?? false,
);
// const isPickerDisabled = computed<boolean>(() => files.length >= maxFiles.value);

// Form Validation
const isImportCsvFormValid = computed<boolean>(() => files.value.length === 1 && !rejected.value);

const err = computed<{ [key: string]: string | null }>(() => {
  const {
    company,
    idAccount,
    zipCode,
    email,
    nSiret,
    nTva,
    address,
    city,
    deliveryAddress,
    deliveryCity,
    deliveryZipCode,
    idCountry,
  } = tier.value;
  return {
    company: !isDefined(company) ? "Le champ est requis." : null,
    idAccount: !isDefined(idAccount) ? "Le champ est requis." : null,
    address: !isDefined(address) ? "Le champ est requis." : null,
    city: !isDefined(city) ? "Le champ est requis." : null,
    zipCode: !isDefined(zipCode) ? "Le champ est requis." : null,
    email:
      isDefined(email) && email.length > 0 && !isEmailString(email)
        ? "L'adresse e-mail est invalide."
        : null,
    nSiret:
      isDefined(nSiret) && nSiret.length > 0
        ? isNSiretString(nSiret) || idCountry !== CountryId.FRANCE
          ? null
          : "Doit contenir exactement 14 chiffres."
        : null,
    nTva:
      isDefined(nTva) && nTva.length > 0 && !isNTvaString(nTva)
        ? "Doit être 2 lettres, puis 8 à 12 chiffres ou lettres. Les lettres doivent être en majuscules."
        : null,
    deliveryAddress:
      shouldUseTwoAddress.value && !isDefined(deliveryAddress) ? "Le champ est requis." : null,
    deliveryCity:
      shouldUseTwoAddress.value && !isDefined(deliveryCity) ? "Le champ est requis." : null,
    deliveryZipCode:
      shouldUseTwoAddress.value &&
      !isDefined(deliveryZipCode) &&
      // @ts-expect-error
      (deliveryZipCode?.length ?? 0) < 5
        ? "Le champ est requis."
        : null,
  };
});

const isValid = computed<boolean>(() => {
  for (const property in err.value) {
    const value = err.value[property];
    if (isDefined(value)) {
      return false;
    }
  }
  return true;
});

// Categories
const filteredCategories = computed<IAccount[]>(() => {
  const fCategories = getCategoriesForAnnotating({
    debit: !props.isCustomer,
  });

  return fCategories.map((c) => {
    return {
      ...c,
      name: hasAccountNumberDisplay.value ? `${c.number} ${c.name}` : c.name,
    };
  });
});

// Actions
async function saveThirdParty() {
  // thirdparty.createForSociety
  isLoading.value = true;
  if (props.editTier) {
    await $silex()
      .thirdParty.update(props.editTier.id!, tier.value as UpdateThirdPartyForm)
      .then((thirdParty: IThirdParty) => {
        thirdPartyStore.setTier({ thirdPartyId: props.editTier!.id as number, thirdParty });
        $notifier().open({ type: "success", content: "Changement enregistré" });
        emit("select", thirdParty);
      })
      .catch((error: any) => {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
        isLoading.value = false;
      });
    isLoading.value = false;
  } else {
    let newThirdPartyId: number | null = null;
    await $silex()
      .thirdParty.createForSociety(userStore.currentSocietyId!, tier.value as CreateThirdPartyForm)
      .then((res: IThirdParty) => {
        newThirdPartyId = res.id ?? null;
        thirdPartyStore.setNewThirdParty(res);
      })
      .catch((error: any) => {
        isLoading.value = false;
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      });

    isLoading.value = false;
    emit("select", newThirdPartyId);
  }
}

async function addNewTiersCsv() {
  try {
    isLoading.value = true;
    const result = await $silex().thirdParty.import(userStore.currentSocietyId!, {
      file: files.value[0],
      idThirdPartyType: props.isCustomer ? ThirdPartyType.CUSTOMER : ThirdPartyType.PROVIDER,
    });
    await thirdPartyStore.fetchThirdPartiesAndGenericThirdParties();
    $notifier().open({
      type: "success",
      content: `${result.length} ${props.isCustomer ? "client" : "fournisseur"}${result.length > 1 ? "s" : ""} ${result.length > 1 ? "ont" : "a"} été importé${result.length > 1 ? "s" : ""}`,
    });
    emit("close");
  } catch (error) {
    $notifier().open({ content: apiErrorToString(error) });
  }
  isLoading.value = false;
}

function clearValues() {
  tier.value = {
    idThirdPartyType: props.isCustomer ? ThirdPartyType.CUSTOMER : ThirdPartyType.PROVIDER,
    company: null,
    fullname: null,
    idAccount: null,
    address: null,
    zipCode: null,
    city: null,
    idCountry: null,
    phone: null,
    email: null,
    nSiret: null,
    nTva: null,
    soldeInit: 0,
    isGeneric: true,
    deliveryAddress: null,
    deliveryCity: null,
    deliveryZipCode: null,
    deliveryIdCountry: null,
  };
}

function overrideTierForm(selectedTierInfo: LegalFormSiren) {
  const {
    codpos: zipCode,
    l1_normalisee: company,
    l4_declaree: address,
    libcom: city,
  } = selectedTierInfo;

  tier.value = {
    ...tier.value,
    ...{
      zipCode,
      company,
      address,
      city,
    },
  };
}
</script>

<style lang="scss" scoped>
:deep(.modal-content) {
  min-height: 430px !important;
}

.ntp-switch-box {
  display: flex;
  align-items: center;
  gap: $uds-spacing-1;
  width: 100%;
  justify-content: center;
  margin-bottom: $uds-spacing-2;
}

/// CREATION FORM
.pf-form {
  flex-grow: 1;
  margin-bottom: 10px;

  .pf-row {
    display: flex;
    &:not(:first-child) {
      margin-top: 15px;
    }

    > * {
      flex-basis: 100%;
      flex-grow: 1;
      &:not(:first-child) {
        margin-left: 15px;
      }
    }

    .row-checkbox {
      display: flex;
      gap: $uds-spacing-1;
      align-items: center;
      width: 100%;
    }
  }
}

/// CSV IMPORT
.ntp-file-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: $uds-spacing-1;
  width: 100%;
  color: black;
}
</style>
